/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from 'react';
import {enquireScreen} from 'enquire-js';

import Nav3 from './Nav3';
import Banner5 from './Banner5';
import Content13 from './Content13';
import Feature1 from './Feature1';
import Feature2 from './Feature2';
import Feature4 from './Feature4';
import Feature0 from './Feature0';
import Feature8 from './Feature8';
import Footer2 from './Footer2';
import ShowGithubRepo from './ShowGithubRepo'
import {
    Nav30DataSource,
    Banner50DataSource,
    Content130DataSource,
    Feature10DataSource,
    Feature21DataSource,
    Feature20DataSource,
    Feature40DataSource,
    Feature00DataSource,
    Feature81DataSource,
    Footer20DataSource,
} from './data.source';
import './less/antMotionStyle.less';

let isMobile;
enquireScreen((b) => {
    isMobile = b;
});

const {location} = window;

export default class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile,
            show: !location.port, // 如果不是 dva 2.0 请删除
        };
    }

    componentDidMount() {
        // 适配手机屏幕;
        enquireScreen((b) => {
            this.setState({isMobile: !!b});
        });
        // dva 2.0 样式在组件渲染之后动态加载，导致滚动组件不生效；线上不影响；
        /* 如果不是 dva 2.0 请删除 start */
        if (location.port) {
            // 样式 build 时间在 200-300ms 之间;
            setTimeout(() => {
                this.setState({
                    show: true,
                });
            }, 500);
        }
        /* 如果不是 dva 2.0 请删除 end */
    }

    render() {
        const children = [
            <Nav3
                id="Nav3_0"
                key="Nav3_0"
                dataSource={Nav30DataSource}
                isMobile={this.state.isMobile}
            />,
            <Banner5
                id="Banner5_0"
                key="Banner5_0"
                dataSource={Banner50DataSource}
                isMobile={this.state.isMobile}
            />,
            <Content13
                id="Content13_0"
                key="Content13_0"
                dataSource={Content130DataSource}
                isMobile={this.state.isMobile}
            />,
            <Feature1
                id="Feature1_0"
                key="Feature1_0"
                dataSource={Feature10DataSource}
                isMobile={this.state.isMobile}
            />,
            <Feature2
                id="Feature2_1"
                key="Feature2_1"
                dataSource={Feature21DataSource}
                isMobile={this.state.isMobile}
            />,
            <Feature2
                id="Feature2_0"
                key="Feature2_0"
                dataSource={Feature20DataSource}
                isMobile={this.state.isMobile}
            />,
            <Feature4
                id="Feature4_0"
                key="Feature4_0"
                dataSource={Feature40DataSource}
                isMobile={this.state.isMobile}
            />,
            <Feature0
                id="Feature0_0"
                key="Feature0_0"
                dataSource={Feature00DataSource}
                isMobile={this.state.isMobile}
            />,
            <Feature8
                id="Feature8_1"
                key="Feature8_1"
                dataSource={Feature81DataSource}
                isMobile={this.state.isMobile}
            />,
            <Footer2
                id="Footer2_0"
                key="Footer2_0"
                dataSource={Footer20DataSource}
                isMobile={this.state.isMobile}
            />,
            <ShowGithubRepo/>
        ];
        return (
            <div
                className="templates-wrapper"
                ref={(d) => {
                    this.dom = d;
                }}
            >
                {/* 如果不是 dva 2.0 替换成 {children} start */}
                {this.state.show && children}
                {/* 如果不是 dva 2.0 替换成 {children} end */}
            </div>
        );
    }
}
