import React from "react";
import {Icon, notification} from "antd";
import GitHubButton from "react-github-btn";


class ShowGithubRepo extends React.PureComponent {
    githubNode = () => {
        return (
            <div>
                {/*https://buttons.github.io/*/}
                我们需要您的支持，请点击按钮帮助我们增加 Github Star。
                <span className="github-btn">
                    <GitHubButton href="https://github.com/lxchinesszz/tomato"
                                  data-color-scheme="no-preference: light; light: light; dark: light;"
                                  data-icon="octicon-star" data-show-count="true"
                                  aria-label="Star lxchinesszz/mojito on GitHub">Star</GitHubButton>
                <span>  </span>
                <GitHubButton href="https://github.com/lxchinesszz/tomato/fork"
                              data-color-scheme="no-preference: light; light: light; dark: light;"
                              data-icon="octicon-repo-forked" data-show-count="true"
                              aria-label="Fork lxchinesszz/mojito on GitHub">Fork</GitHubButton>
                </span>
            </div>
        );
    }

    componentDidMount() {
        const openNotification = placement => {
            notification.info({
                message: `关注下 Star 数`,
                description: this.githubNode(),
                placement,
                duration: 20,
                icon: <Icon type="github"/>
            });
        };
        openNotification('bottomLeft');
    }

    render() {
        return (<p></p>);
    }
}

export default ShowGithubRepo;
